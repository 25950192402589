<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="divForm">
    <form [formGroup]="editGroupModelForm" (onSubmit)="submit()">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Nombre }}</mat-label>
          <input matInput formControlName="NombreGruposClientes" />
          <mat-error matInput *ngIf="validateFields('NombreGruposClientes')">
            {{ getErrorMessage("NombreGruposClientes") }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="input-select" appearance="outline">
          <mat-select
            placeholder="{{ translation.Tipo }}"
            formControlName="TipoElemento"
            name="TipoElemento"
            (selectionChange)="tipoElementChang($event)"
          >
            <mat-option
              *ngFor="let tiponivelentrada of tipoElemento"
              [value]="tiponivelentrada"
            >
              {{ tiponivelentrada?.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="validateFields('TipoElemento')">
            {{ getErrorMessage("TipoElemento") }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Nivel }}</mat-label>
          <input type="number" matInput formControlName="Nivel" />
          <mat-error *ngIf="validateFields('Nivel')">
            {{ getErrorMessage("Nivel") }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="input-select" appearance="outline">
          <mat-select
            placeholder="{{ translation.Padre }}"
            [compareWith]="compareCategoryObjectsFn"
            formControlName="Padre"
            name="Padre"
          >
            <mat-option
              *ngFor="let tipoElomento of padres"
              [value]="tipoElomento"
            >
              {{ tipoElomento.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Orden }}</mat-label>
          <input type="number" matInput formControlName="Orden" />
          <mat-error *ngIf="validateFields('Orden')">
            {{ getErrorMessage("Orden") }}
          </mat-error>
        </mat-form-field>
      </div>

      <div style="margin: 20px 5px 0 5px; text-align: center">
        <mat-label>{{ translation.Centros }}</mat-label>

        <customan-dual-list
          [source]="centrosSinAsignar"
          [(destination)]="centrosAsignados"
          key="pkId"
          display="Nombre"
          [format]="format"
          [filter]="true"
        >
        </customan-dual-list>
      </div>
    </form>
  </div>
</div>
